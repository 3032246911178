import { useState, useEffect } from "react";

type Position = {
  id: string;
  comeet_position_uid: string;
  title: string;
  location: string;
  tags: string[];
  description?: string;
  responsibilities?: string;
  requirements?: string;
};

export default function Careers({ limit }: { limit?: number }) {
  const [positions, setPositions] = useState<Position[]>([]);

  useEffect(() => {
    fetch(`/api/positions?limit=${limit}`)
      .then((response) => response.json())
      .then(({ positions }) => setPositions(positions));
  }, [limit]);

  return (
    <div>
      {positions.map((pos) => (
        <div className="together-work-s">
          <div className="left-side">
            <h3>
              <a href={`/careers/${pos.id}`}>{pos.title}</a>
            </h3>
            <div className="d-options">
              <div className="d-option">
                <span className="txt">{pos.location}</span>
              </div>
              {pos.tags.map((tag) => (
                <div className="d-option">
                  <span className="txt">{tag}</span>
                </div>
              ))}
            </div>
          </div>
          <div className="right-side">
            <a href={`/careers/${pos.id}`} className="link-button">
              <i className="fa fa-chevron-right" />
            </a>
          </div>
        </div>
      ))}
    </div>
  );
}
